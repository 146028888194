fetch('./config/config.json', {cache: 'reload'})
  .then(res => res.json())
  .then(config => {
    import(/* @vite-ignore */config.ssoKeycloakAdapter)
      .then(() => {
        window.keycloak = new Keycloak({
          url: config.ssoUrl,
          realm: config.ssoRealm,
          clientId: config.ssoClient
        })
        window.keycloak.init({
          onLoad: 'login-required',
          promiseType: 'native',
          checkLoginIframe: false,
          pkceMethod: 'S256'
        }).then(authenticated => {
          document.querySelector('body').innerHTML = `
<main>
  <reservierung-frontend-header>
  </reservierung-frontend-header>
  <reservierung-frontend-horizontal-navigation></reservierung-frontend-horizontal-navigation>
  <div id="maincontent">
    <reservierung-frontend-message></reservierung-frontend-message>
    <reservierung-frontend-main-view></reservierung-frontend-main-view>
  </div>
  <reservierung-frontend-footer></reservierung-frontend-footer>
</main>
                  `
        }).catch(error => {
          console.info('error', error)
        })
      })
  })
