import WebComponent from '../../../WebComponent.js';
import {html} from '@isceco/widget-library2/external/lit';
import BookingItemsService from '../../../services/BookingItemsService.js';
import BookingsService from '../../../services/BookingsService.js';
import DateTimeFormatter from '../../../formatter/DateTimeFormatter.js';
import Reservation from './Reservation.js';
import '@isceco/widget-library2/basic-elements/Form/Form.js'
import '@isceco/widget-library2/basic-elements/RadioGroup/RadioGroup.js'
import ValidatorMsg from '../../../validator/ValidatorMsg.js';
import Validator from '../../../validator/Validator.js';

/**
 * In this class the dialog to book a booking is defined
 * Additionally this dialog can be used to update a booking
 * @author Loris Gasser
 */
export default class BookingDialog extends WebComponent {

  constructor(userId) {
    super();
    this.validator = new ValidatorMsg()
    this.dateTimeFormatter = new DateTimeFormatter()
    this.updatingBooking = null
    this.userId = userId
    this.options = [{
      value: '0',
      name: 'Ganztägig',
      startTime: '06:00',
      endTime: '18:00',
      timespan: '12h',
      price: '8.00-.'
    },
      {
        value: '1',
        name: 'Vormittag',
        startTime: '06:00',
        endTime: '12:00',
        timespan: '6h',
        price: '4.00-.'
      },
      {
        value: '2',
        name: 'Nachmittag',
        startTime: '12:00',
        endTime: '18:00',
        timespan: '6h',
        price: '4.00-.'
      },
    ]
  }

  connectedCallback() {
    super.connectedCallback();
    const itemId = this.getIdFromUrl()

    new BookingsService().list('user/' + this.userId).then(result => {
      this.bookingsByUser = result
      this.reload()
    })
    new BookingItemsService().list('item/' + itemId).then(result => {
      this.item = result
      this.setOptionsWithDescription()
      this.reload()
    })
    new BookingsService().list('item/' + itemId).then(result => {
      this.bookingsByItem = result
      this.reload()
    })
    new BookingsService().list('description/G').then(result => {
      this.bookingsByDescriptionCount = result
      this.reload()
    })
    new BookingItemsService().list('description/G').then(result => {
      this.allDayParkingspotCount = result
      this.reload()
    })

  }

  getTemplate() {
    return html`
      <isceco-dialog id="form-modal"
                     hidden
                     header="Buchung tätigen"
                     description="Um den Parkplatz zu buchen oder zu aktualisieren müssen Sie noch die folgenden Angaben bearbeiten:"
                     confirm-button="Buchen"
                     Cancel-button="Abbrechen"
                     @submit="${(e) => {
                       e.target.setAttribute("hidden", "")
                       this.submit(e)
                     }}">
        <isceco-radio-group
          label="Art der Buchung" class="form-input"
          .items="${this.options}"

          @change="${e => this.changeValues(e.target.value)}"
          required value="0" id="radio-group"
        ></isceco-radio-group>
        <isceco-text-input required class="form-input" type="date" id="date" label="Datum"></isceco-text-input>
        <isceco-text-input readonly required value="${this.options[0].startTime}" class="form-input" type="time"
                           id="startTime"
                           label="Von"></isceco-text-input>
        <isceco-text-input required value="${this.options[0].endTime}" readonly class="form-input" id="endTime"
                           type="time"
                           label="Bis"></isceco-text-input>
        <isceco-text-input required id="licensePlate"
                           pattern-error-text="Beachten Sie dass Sie zunächst zwei Grossbuchstaben und anschliessend die richtigen Ziffern ihrer Kfz. Nummer eingegeben haben"
                           pattern="^[A-Z]{0,2}[ ]{0,1}[0-9]{1,3}[ ]{0,1}[0-9]{0,3}$" class="form-input"
                           label="Kfz. Nummer"></isceco-text-input>
        <isceco-text-input value="${this.options[0].timespan}" required class="form-input-readonly"
                           id="timespan"
                           readonly label="Zeit"></isceco-text-input>
        <isceco-text-input id="price" value="${this.options[0].price}" required class="form-input-readonly"
                           readonly
                           label="Preis"></isceco-text-input>
      </isceco-dialog>`
  }

  /**
   * Defines how to handle if the User clicks on a button of the dialog
   * Submits the new booking if there is no validation error shown
   * @param event The Event that is passed when it was clicked
   */
  submit(event) {
    if (event.detail.confirmed) {
      const date = document.getElementById('date')
      const licensePlateInput = document.getElementById('licensePlate')

      const startTime = this.dateTimeFormatter.setDateTime(date.value, document.getElementById('startTime').value)
      const endTime = this.dateTimeFormatter.setDateTime(date.value, document.getElementById('endTime').value)
      const price = Number(document.getElementById('price').value.split('.')[0])
      const bookingItemId = Number(this.item.id)
      const bookingUserId = Number(this.userId)
      const licensePlate = licensePlateInput.value

      const booking = new Reservation(formatDateTime(startTime), formatDateTime(endTime), price, licensePlate, bookingItemId, bookingUserId)

      this.validator.isDateValid(startTime)

      this.bookingsByItem.map(booking => {
        if (this.updatingBooking === null || booking.id !== this.updatingBooking.id) {
          this.validator.isBookingBooked(endTime, startTime, booking)
          this.validator.isBookingOverlapping(endTime, startTime, booking)
        }

      })
      this.validator.isBookingByUserValid(startTime, this.bookingsByUser, this.updatingBooking)
      this.bookingsByItem.map(booking => this.validator.isHalfDayBookingValid(startTime, this.allDayParkingspotCount, this.bookingsByDescriptionCount, booking))


      if (!window.alerts.some(this.validator.checkValidation)) {
        if (this.updatingBooking !== null) {
          new BookingsService().update(booking, 'update/' + this.updatingBooking.id).then(_ => {
            this.updatingBooking = null
            location.reload()
          })
        } else {
          new BookingsService('bookings/post').create(booking).then(_ => {
            location.reload()
          })
        }
      }
    }
  }

  /**
   * Changes the values of the inputfields
   * @param optionsValue The Value for the options in the radio group
   * @param booking The Booking in case it is about a booking to update
   * @param date The Date for the dateinput
   */
  changeValues(optionsValue, booking, date = null) {
    if (date !== null) {
      document.getElementById('date').value = this.dateTimeFormatter.formatInputDate(date)
    }
    document.getElementById('radio-group').value = optionsValue
    document.getElementById("startTime").value = this.options[optionsValue].startTime
    document.getElementById("endTime").value = this.options[optionsValue].endTime
    document.getElementById('price').value = this.options[optionsValue].price
    document.getElementById('timespan').value = this.options[optionsValue].timespan

    if (booking) {
      this.updatingBooking = booking
      document.getElementById('date').value = this.dateTimeFormatter.formatInputDate(new Date(this.updatingBooking.startTime))
      document.getElementById("licensePlate").value = this.updatingBooking.bookingReference
    }
  }


  /**
   * Sets the options based on the description of the item
   */
  setOptionsWithDescription() {
    if (new Validator().checkDescription(this.item) !== null) {
      this.options.shift()
      for (let i = 0; i < this.options.length; i++) {
        this.options[i].value = i
      }
    }
  }

}
customElements.define('reservierung-frontend-booking-dialog', BookingDialog)
