import {html} from '@isceco/widget-library2/external/lit';
import WebComponent from '../WebComponent.js';
import DateTimeFormatter from '../formatter/DateTimeFormatter.js';


/**
 * All Validations that are needed for a new Booking are done here
 * @author Loris Gasser
 */
export default class Validator extends WebComponent {

  constructor() {
    super();
    this.dateTimeFormatter = new DateTimeFormatter()
    this.maxWorkDays = 21
    this.maxBookings = 3
    this.workDaysCount = 5
  }


  getTemplate() {
    return html`reservierung-frontend-validator`
  }

  /**
   * Checks if the User has done more than three Reservations for the week
   * @param startTime The StartTime for the booking the user wants to make
   * @param bookingsByUser All Bookings done by one User
   * @returns {boolean} A Boolean that is true if it is a Booking too much and false if it is not
   */

  isUserBookingMax(startTime, bookingsByUser) {
    const startWeek = this.dateTimeFormatter.getStartOfTheWeek(startTime)
    const endWeek = this.dateTimeFormatter.setWeekEnd(startWeek)
    let bookingsCounter = 0
    bookingsByUser.map(booking => {
      if (new Date(booking.startTime) > startWeek && new Date(booking.startTime) < endWeek) {
        bookingsCounter++
      }
    })
    return bookingsCounter + 1 > this.maxBookings
  }

  /**
   * Checks if the Booking is overlapping with another
   * @param endTime The EndTime of the new booking
   * @param startTime The StartTime of the new booking
   * @param booking The already existing booking
   * @returns {boolean} A Boolean that says true if it is overlapping and false if it is not
   */
  isBookingOverlapped(endTime, startTime, booking) {
    let isBookingOverlapped = false
    const bookingStartTime = new Date(booking.startTime)
    const bookingEndTime = new Date(booking.endTime)

    if (Math.max(startTime, bookingStartTime) < Math.min(endTime, bookingEndTime)) {
      isBookingOverlapped = true
    }
    return isBookingOverlapped
  }

  /**
   * Checks if the new Booking is already booked
   * @param endTime The EndTime of the new booking
   * @param startTime The StartTime of the new booking
   * @param booking The already existing booking
   * @returns {boolean} The Boolean is true if it is already booked and false if it is not
   */
  isBookingBooked(endTime, startTime, booking) {
    let isBookingBooked = false
    const bookingStartTime = new Date(booking.startTime)
    const bookingEndTime = new Date(booking.endTime)
    if (this.dateTimeFormatter.compareDateTime(bookingStartTime, startTime) && this.dateTimeFormatter.compareDateTime(bookingEndTime, endTime)) {
      isBookingBooked = true
    }


    return isBookingBooked
  }

  /**
   * Checks if the date of the new booking is present
   * @param date The Date of the new booking to check
   * @returns {boolean} Returns true if it is in the past and false if it is not
   */
  isDatePresent(date) {
    const presentDay = new Date()
    return date.getTime() < presentDay.getTime()
  }

  /**
   * Checks if the date is too far away to book
   * @param date The Date that has to be checked
   * @returns {boolean} Returns true if it too far away and false if it is not
   */
  isDateTooFar(date) {
    const currentDate = new Date()
    const dateInput = new Date(date)
    currentDate.setDate(new Date().getDate() + this.maxWorkDays)
    currentDate.setHours(0, 0, 0)
    dateInput.setHours(0, 0, 0, 0)
    return dateInput.getTime() > currentDate.getTime()
  }

  /**
   * Checks if the description of the item says that it is a parking place for half of a day
   * @param item The Item that the description is checked
   * @returns {boolean|null} Returns true if it is a halfday parking spot and null else
   */
  checkDescription(item) {
    return item.description.charAt(0) === 'H' ? true : null
  }

  /**
   * Checks if all allday parking spots are already taken and if the user can take a halfday spot for full day
   * @param startTime The StartTime of the new booking
   * @param allDayParkingspotsCount The Count of parking places that can be booked all day
   * @param bookingsByDescriptionCount The Count of bookings made for allday parking spaces
   * @param booking The booking that has to be checked with
   * @returns {boolean} Returns True if he is trying to book the halfday spot for a full day and false if he is not
   */
  checkHalfDayBooking(startTime, allDayParkingspotsCount, bookingsByDescriptionCount, booking) {
    let isHalfDayValid = false
    if (this.checkDescription(booking.bookingItem) && bookingsByDescriptionCount < allDayParkingspotsCount * this.workDaysCount) {
      if (startTime.getDate() === new Date(booking.startTime).getDate()
        && startTime.getMonth() === new Date(booking.startTime).getMonth()) {
        isHalfDayValid = true
      }
    }
    return isHalfDayValid
  }

}
customElements.define('reservierung-frontend-validator', Validator)
