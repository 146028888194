import {html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/Button/Button.js'
import WebComponent from '../../WebComponent.js'
import AuthorizationFilter from "../Authorization/AuthorizationFilter";
import './Navigation/AdministrationNavigation.js'
import AdminBookinItems from './AdminBookingItems/AdminBookingItems'
import User from "./User/User";

export default class Administration extends WebComponent {

  get translationFile() {
    return './views/Administration/i18n.json'
  }

  connectedCallback() {
    super.connectedCallback()

    // set default view
    if (getNavigationHash() === 'Administration') {
      navigate({to: 'Administration_BookingItems'});
    }
    this.render()
  }

  getTemplate() {
    return html`
      <div style="display: flex; height: auto">
        <div style="flex-grow: 0; margin-top: 10px;">
          <reservierung-frontend-administration-navigation></reservierung-frontend-administration-navigation>
        </div>
        <div id="administrationContent" style="flex-grow: 1; margin-left: 20px; margin-top: 20px;">
          ${this.getContent()}
        </div>
      </div>
    `
  }

  getContent() {  //NOSONAR
    // check the rights and redirect to default view in verwaltung if not ok
    if (!AuthorizationFilter.viewAllowed(getNavigationHash())) {
      navigate({to: 'notAuthorized'})
      return html``
    }
    switch (getNavigationHash()) {
      case 'Administration_BookingItems':
        return new AdminBookinItems();
      case 'Administration_User':
        return new User();

        default:
        break;
    }
    // default
    return html`Default`
  }

  get css() {
    return `
            div {
                text-align: left;
            }
        `
  }
}
customElements.define('reservierung-frontend-administration', Administration)
