import BaseService from '../BaseService.js';

/**
 * All Requests for data of bookingItems use this class
 * The base of the URL is given
 * @author Loris Gasser
 */
export default class BookingItemsService extends BaseService{

  constructor() {
    super('bookingItems');
  }
}
