export default class Authorization {
  static get ROLES() {
    return {
      betrieb: 'FA-INT-Ausbildung.BETRIEB',
      admin: 'FA-INT-Ausbildung.ADMIN',
      super: 'FA-INT-Ausbildung.SUPER',
      user: 'FA-INT-Ausbildung.USER'
    }
  }

  static VIEWS() {
    return {
      notAuthorized: 'NotAuthorized'
    }
  }
}
