import '@isceco/widget-library2/basic-elements/Title/Title.js'
import '@isceco/widget-library2/basic-elements/Dropdown/Dropdown.js'
import '@isceco/widget-library2/basic-elements/TextInput/TextInput.js'
import '@isceco/widget-library2/basic-elements/Button/Button.js'
import {css, html} from '@isceco/widget-library2/external/lit';
import WebComponent from '../../WebComponent.js';
import LogSettingsService from './LogSettingsService.js';

export default class LogSettings extends WebComponent {

  get css() {
    return css`
      .log-setting-container {
        min-width: 800px;
        width: 50%;
      }

      .log-setting-container > *:not(:first-child) {
        margin-top: var(--isceco-space-200);
      }

      .log-setting-container ul {
        list-style-type: disc;
        padding: revert;
      }

      @media (max-width: 991.98px) {
        .log-setting-container {
          min-width: 0;
          width: 100%;
        }
      }
    `
  }

  connectedCallback() {
    super.connectedCallback()
    this.setLogMessage = ''
    this.getLogMessage = ''
    this.render()
  }

  getTemplate() {
    return html`
      <isceco-title id="page-title" text="Log Settings"></isceco-title>

      <div class="log-setting-container">
        <isceco-title size="medium" text="Set Log Level"></isceco-title>
        <isceco-text-input label="Package" id="set-log-name" value="ch" required></isceco-text-input>
        <isceco-dropdown label="Level" id="set-log-level" required
                         placeholder="---"
                         .items="${[
                           {value: 'FATAL', name: 'FATAL'},
                           {value: 'ERROR', name: 'ERROR'},
                           {value: 'WARN', name: 'WARN'},
                           {value: 'INFO', name: 'INFO'},
                           {value: 'DEBUG', name: 'DEBUG'},
                           {value: 'TRACE', name: 'TRACE'}
                         ]}"
        ></isceco-dropdown>
        <isceco-button text="Set" @click="${_ => this.setLog()}"></isceco-button>
        <p id="set-log-display">${this.setLogMessage}</p>
      </div>

      <div class="log-setting-container">
        <isceco-title size="medium" text="Get Log Level"></isceco-title>
        <isceco-text-input label="Package" id="get-log-name" value="ch" required></isceco-text-input>
        <isceco-button text="Get" @click="${_ => this.getLog()}"></isceco-button>
        <p id="get-log-display">${this.getLogMessage}</p>
      </div>
    `
  }

  getLog() {
    const name = document.getElementById('get-log-name').value
    if (isEmpty(name)) {
      this.getLogMessage = html`
        <span style="color: var(--isceco-color-red-500)">Name may not be empty.</span>
      `
      this.render()
      return
    }

    const logService = new LogSettingsService()
    logService.list(name)
      .then(json => {
        if (json.length === 0) {
          this.getLogMessage = html`
            <span style="color: var(--isceco-color-red-500)">Could not get Log Level for ${name}.</span>
          `
        } else {
          const levelsRendered = json.map(log => html`
            <li>Log Level of <b>${isEmpty(log.name) ? '*' : log.name}</b> is <b>${log.level}</b></li>
          `)
          this.getLogMessage = html`
            Found <b>${json.length}</b> Loggers: <ul>${levelsRendered}</ul>
          `
        }
        this.render()
      })
  }

  setLog() {
    const name = document.getElementById('set-log-name').value
    const level = document.getElementById('set-log-level').value
    if (isEmpty(name) || isEmpty(level)) {
      this.setLogMessage = html`
        <span style="color: var(--isceco-color-red-500)">Name and Level may not be empty.</span>
      `
      this.render()
      return
    }

    const logService = new LogSettingsService()
    logService.create({level: level}, `/${name}`)
      .then(json => {
        const namesRendered = json.names.map(nameOnly => html`
          <li><b>${nameOnly}</b></li>
        `)
        this.setLogMessage = html`
          Set Log Level to <b>${json.level}</b>, <b>${json.names.length}</b> Loggers updated: <ul>${namesRendered}</ul>
        `
        this.render()
      })
  }
}

customElements.define('reservierung-frontend-log-settings', LogSettings)
