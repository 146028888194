import WebComponent from '../../../WebComponent.js';
import {html} from '@isceco/widget-library2/external/lit';
import BookingItemColumns from '../../../columns/BookingItemColumns.js';
import '@isceco/widget-library2/basic-elements/List/List.js'
import BookingItemsService from '../../../services/BookingItemsService.js';

/**
 * In this WebComponent shows all available Items in a list
 * For that the columns that are defined are used
 * @author Loris Gasser
 */
export default class BookingItems extends WebComponent{

  /**
   * Gets the JSON file for translations
   * @returns {string} The Path of the file
   */
  get translationFile() {
    return './views/Booking/BookingItems/i18n.json'
  }
  constructor(){
    super();
    this.bookingItemsListService = new BookingItemsService()

  }

   async connectedCallback() {
    super.connectedCallback();
    this.columns = [
      BookingItemColumns.AVAILABLE_COLUMN(),
      BookingItemColumns.NAME_COLUMN(),
      BookingItemColumns.INVENTORY_CODE_COLUMN(),
      BookingItemColumns.TYPE_COLUMN(),
      BookingItemColumns.BASIC_PRICE_COLUMN(),
      BookingItemColumns.DESCRIPTION_COLUMN()
    ]

    await this.bookingItemsListService.getBackendListUrl('available')
      .then(url=> this.url = url).then(_ => this.reload())
  }

  getTemplate(){
    return html `
      <h1>Parkplätze</h1>
      <isceco-list
        .columns="${this.columns}"
        url="${this.url}"
        .i18n="${this.i18n}"
        .dtoMapper="${null}"
        identifier="bookingItemsList"
        @rowclick="${e => {
          navigate({to: 'DetailBooking/'+ e.detail.row.id})
        }}"
      ></isceco-list>
    `
  }
}
customElements.define('reservierung-frontend-booking-items', BookingItems)
