import StringColumn from '@isceco/widget-library2/basic-elements/List/column/StringColumn.js';

/**
 * All columns for a list of BookingItems are defined
 * @author Loris Gasser
 */
export default class BookingItemColumns {


  static NAME_COLUMN(){
    return new StringColumn({
      key: 'name',
      text: 'bookingItem.name',
      sortable: true
    })
  }

  static AVAILABLE_COLUMN(){
    return new StringColumn({
      key: 'available',
      text: 'bookingItem.available',
      sortable: true
    })
  }

  static BASIC_PRICE_COLUMN(){
    return new StringColumn({
      key: 'basicPrice',
      text: 'bookingItem.basicPrice',
      sortable: true
    })
  }

  static TYPE_COLUMN(){
    return new StringColumn({
      key: 'type',
      text: 'bookingItem.type',
      sortable: true
    })
  }

  static DESCRIPTION_COLUMN(){
    return new StringColumn({
      key: 'description',
      text: 'bookingItem.description',
      sortable: true
    })
  }

  static INVENTORY_CODE_COLUMN(){
    return new StringColumn({
      key: 'inventoryCode',
      text: 'bookingItem.inventoryCode',
      sortable: true
    })
  }
}
