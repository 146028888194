import '@isceco/widget-library2/index.js'
import '@isceco/widget-library2/core.css'
import './views/Header/Header.js'
import './views/Message/Message.js'
import './views/Navigation/Navigation.js'
import './views/Navigation/HorizontalNavigation.js'
import './views/MainView/MainView.js'
import './views/Footer/Footer.js'

window.iscecoWidgetLibrary.languageKey = 'Reservierung-frontend'
window.iscecoWidgetLibrary.supportedLanguages = ['de', 'fr', 'it', 'en']

window.hasChanges = false

if (!window.containsAlert) {
  window.containsAlert = () => isEmpty(window.alerts) ? false : window.alerts.length > 0
}

window.setNavigationParams = queryParamsString => {
  const baseUrl = location.origin + location.pathname + location.search
  const navigationHash = getNavigationHash() + getNavigationIds()
  const url = `${baseUrl}#${navigationHash}?${queryParamsString}`
  history.replaceState(null, null, url);
};

window.getNavigationIds = () => {
  const idArray = window.location.hash.split('?')[0].split('/');
  if (idArray.length <= 1) {
    return ''
  }
  idArray.shift()
  return '/' + idArray.join('/');
}
