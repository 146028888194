import {css, html} from '@isceco/widget-library2/external/lit';
import '@isceco/widget-library2/basic-elements/Header/Header.js'
import '@isceco/widget-library2/basic-elements/LanguageSwitcher/LanguageSwitcher.js'
import WebComponent from '../../WebComponent.js';


export default class Header extends WebComponent {

  get translationFile() {
    return './views/Header/i18n.json'
  }

  connectedCallback() {
    super.connectedCallback()
    this.render()
  }

  get css() {
    return css`
      .user-language {
        display: flex;
        align-items: end;
        flex-direction: column;
      }
    `
  }

  getTemplate() {
    return html`
      <isceco-header text="${this.i18n.translate('header.text')}">
        <isceco-message></isceco-message>
        <div class="user-language">
           <isceco-language></isceco-language>
          <div class="user-menu">
            <isceco-link text="${this.displayName()}"
                         icon="user circle outline"
                         @click="${e => this.toggleActionMenu(e, false)}">
            </isceco-link>
            <isceco-action-menu hidden id="user-action-menu"
                                .items="${[
                                  {
                                    icon: 'sign out',
                                    text: this.i18n.translate('header.logout'),
                                    callback: _ => window.keycloak.logout()
                                  }
                                ]}"
                                @submit="${e => this.toggleActionMenu(e, true)}">
            </isceco-action-menu>
          </div>
        </div>
      </isceco-header>
    `
  }
  toggleActionMenu(event, hidden) {
    event.preventDefault()
    const actionMenu = document.getElementById('user-action-menu')
    actionMenu.hidden = hidden
    actionMenu.element = event.target
  }

  displayName = () => {
    if (!isEmpty(window.keycloak)) {
      return `${window.keycloak.tokenParsed.given_name} ${window.keycloak.tokenParsed.family_name}`
    } else {
      return ''
    }
  }
}

customElements.define('reservierung-frontend-header', Header)
