import Authorization from './Authorization.js';

export default class AuthorizationFilter {

  static _initRoles() {
    this.roles = {
      betrieb: window.hasRole(Authorization.ROLES.betrieb),
      user: window.hasRole(Authorization.ROLES.user),
      admin: window.hasRole(Authorization.ROLES.admin),
      super: window.hasRole(Authorization.ROLES.super)
    }
  }

  static viewsAllowed(names) {
    return names.filter(name => this.viewAllowed(name))
  }

  static viewAllowed(name) {
    this._initRoles()
    return true
  }
  static _hasAnyRole() {
    return Object.values(this.roles).find(value => value === true) || false
  }

  static hasAdminRole() {
    this._initRoles()
    return this.roles.admin || this.roles.super
  }

  static hasSuperRole() {
    this._initRoles()
    return this.roles.super
  }
}
