import WebComponent from '../../../WebComponent.js';
import {html} from '@isceco/widget-library2/external/lit';
import BookingItemsService from '../../../services/BookingItemsService.js';
import Calendar from './Calendar.js';
import DateTimeFormatter from '../../../formatter/DateTimeFormatter.js';

/**
 * This is the central class for the detailed view of a bookingItem
 * @author Loris Gasser
 */
export default class DetailBooking extends WebComponent {


  constructor() {
    super();
    this.weekCounter = 0
    this.maxWeekCount = 3
    this.dateTimeFormatter = new DateTimeFormatter()
    this.weekStart = this.dateTimeFormatter.getStartOfTheWeek(new Date());
    this.weekEnd = this.dateTimeFormatter.setWeekEnd(this.weekStart)
  }

  connectedCallback() {
    super.connectedCallback();
    const itemId = this.getIdFromUrl()
    new BookingItemsService().list('item/' + itemId).then(result => {
      this.item = result
      this.calendar = new Calendar(this.weekStart, this.item)
      this.reload()
    })
  }

  getTemplate() {
    return html`
      <div>
        <h1> Terminkalender von ${this.item.name} ${this.item.inventoryCode} </h1>
        <div class="weeklyOverview">
          <i class="angle double left icon" @click="${_ => {
            this.showWeekBefore()
          }}"></i>
          <span>
            ${formatDate(this.weekStart)} - ${formatDate(this.weekEnd)}
          </span>
          <i class="angle double right icon" @click="${_ => {
            this.showWeekAfter()
          }}"></i>
        </div>
        <div>
          ${this.getCalendar()}
        </div>
        <div>
          <isceco-button text="Zurück" style="margin-top: 1em" @click="${_=>navigate({to: 'BookingItems'})}"></isceco-button>
        </div>
      </div>
    `

  }

  /**
   * Returns a calendar
   * To Load the calendar in the detailview
   * @returns {Calendar|*} Returns a calendar
   */
  getCalendar() {
    return this.calendar
  }

  /**
   * Method what happens if the double right icon is clicked
   * Calendar gets newly loaded
   */
  showWeekAfter() {
    if (this.weekCounter !== this.maxWeekCount) {
      this.weekStart.setDate(this.weekStart.getDate() + 7)
      this.weekEnd = this.dateTimeFormatter.setWeekEnd(this.weekStart)
      this.weekCounter++
      this.calendar = new Calendar(this.weekStart)
      this.reload()
    } else {
      window.showAlert('Kalender für nächste Woche konnte nicht geöffnet werden',
        'Beachten Sie, dass Sie nur maximal drei Wochen im Voraus reservieren können', 'warning')
    }
  }

  /**
   * In this method is defined what to do if the double left icon is clicked
   * Calendar gets newly loaded
   */
  showWeekBefore() {
    this.weekStart.setDate(this.weekStart.getDate() - 7)
    this.weekEnd = this.dateTimeFormatter.setWeekEnd(this.weekStart)
    this.weekCounter--
    this.calendar = new Calendar(this.weekStart)
    this.reload()
  }


}
customElements.define('reservierung-frontend-detail-booking', DetailBooking)
