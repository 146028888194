import {html} from '@isceco/widget-library2/external/lit/index.js';
import Validator from './Validator.js';
import WebComponent from '../WebComponent.js';

/**
 * In this class the behaviour if a validation is true is defined
 * @author Loris Gasser
 */
export default class ValidatorMessage extends WebComponent{

  static ERROR_MESSAGE = 'Buchung konnte nicht abgeschlossen werden'
  static ERROR_MESSAGE_HALF_DAY = 'Beachten Sie, dass Sie bei Halbtagesparkplätze nur ganztägige Buchungen tätigen können wenn alle ganztägigen bereits gebucht sind'
  static MESSAGE_TYPE = 'error'
  static ERROR_MESSAGE_BOOKING_BOOKED = 'Die Zeiten dürfen nicht mit denen einer anderen Buchung übereinstimmen'
  static ERROR_MESSAGE_BOOKING_OVERLAPPING = 'Die Zeiten dürfen nicht mit denen einer anderen Buchung überlappen'
  static ERROR_MESSAGE_DATE = 'Beachten Sie, dass das Datum weder in der Vergangenheit ist noch weiter als drei Wochen entfernt ist'
  static ERROR_MESSAGE_USER = 'Beachten Sie, dass Sie nur drei Buchungen pro Woche vornehmen dürfen'

  constructor() {
    super();
    this.validator = new Validator()
  }

  connectedCallback() {
    super.connectedCallback();

  }

  getTemplate(){
    return html`reservierung-frontend-validator`
  }


  /**
   * Checks if an error message that is still shown is an error
   * @param alert The Alert that has to be checked
   * @returns {boolean} Returns true if it is an error
   */
  checkValidation(alert){
    return  alert.type === ValidatorMessage.MESSAGE_TYPE
  }

  /**
   * Shows an error message if the Booking is not valid
   * @param startTime The StartTime of the new booking
   * @param bookingsByUser All Bookings done by one User
   * @param updatingBooking The Booking that is to update
   */
  isBookingByUserValid(startTime, bookingsByUser, updatingBooking){
    if (this.validator.isUserBookingMax(startTime,bookingsByUser) && updatingBooking === null){
      return window.showAlert(ValidatorMessage.ERROR_MESSAGE,
        ValidatorMessage.ERROR_MESSAGE_USER, ValidatorMessage.MESSAGE_TYPE)
    }
  }

  /**
   * Shows an error message if the booking is booked
   * Is only shown if it is about a new booking
   * @param endTime The EndTime of the new booking
   * @param startTime The StartTime with the new booking
   * @param booking The booking that has to be checked with
   */
  isBookingBooked(endTime, startTime, booking){
    if (this.validator.isBookingBooked(endTime, startTime,booking)){
      return window.showAlert(ValidatorMessage.ERROR_MESSAGE, ValidatorMessage.ERROR_MESSAGE_BOOKING_BOOKED, ValidatorMessage.MESSAGE_TYPE)
    }
  }

  isBookingOverlapping(endTime, startTime, booking){
    if (this.validator.isBookingOverlapped(endTime, startTime, booking)){
      return window.showAlert(ValidatorMessage.ERROR_MESSAGE, ValidatorMessage.ERROR_MESSAGE_BOOKING_OVERLAPPING , ValidatorMessage.MESSAGE_TYPE)
    }
  }

  /**
   * Shows an error message if the date is not valid
   * @param date The date that is to check
   */

  isDateValid(date){
    if (this.validator.isDatePresent(date) || this.validator.isDateTooFar(date)){
      return window.showAlert(ValidatorMessage.ERROR_MESSAGE, ValidatorMessage.ERROR_MESSAGE_DATE, ValidatorMessage.MESSAGE_TYPE)
    }
  }

  /**
   * Shows an error message if a halfday parking space gets booked for full day and there are free parking spots for all day
   * @param startTime The StartTime of the new Booking
   * @param bookingItemsCount The Count of parking places that can be booked all day
   * @param bookingsCount The Count of bookings made for allday parking spaces
   * @param booking The Booking that has to be checked with
   */
  isHalfDayBookingValid(startTime, bookingItemsCount, bookingsCount, booking){
    if (this.validator.checkHalfDayBooking(startTime, bookingItemsCount, bookingsCount, booking)){
      return window.showAlert(ValidatorMessage.ERROR_MESSAGE, ValidatorMessage.ERROR_MESSAGE_HALF_DAY,
        ValidatorMessage.MESSAGE_TYPE)
    }
  }

}
customElements.define('reservierung-frontend-validator-message', ValidatorMessage)
