export default class ExceptionService {

  constructor() {
    this.backendUrl = ''
  }

  retrieveBackendUrl() {
    return fetch('./config/config.json', {cache: 'reload'})
      .then(res => res.json())
      .then(config => {
        this.backendUrl = `${config.backendUrl}/logs`
      })
      .catch(error => {
        console.info(error)
      })
  }

  async post(logs) {
    console.info('post logs')
    await this.retrieveBackendUrl()
    return fetch(this.backendUrl, {
      method: 'POST',
      cache: 'reload',
      headers: {
        'Content-Type': 'application/json'
        ,'Authorization': 'Bearer ' + window.keycloak.token
      },
      body: JSON.stringify(logs)
    })
  }

  log(error) {
    const entry = {entry: error.message, stack: error.stack}

    this.post(entry).then(res => {
      if (!res.ok) {
        //error
        console.info('Error: Could not log: ', error)
      }
      //Warning
      console.info('Warning: ',error)
    })
      .catch(err => {
        //error
        console.info('Error: ', err)
      })
  }
}
