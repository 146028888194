/**
 * This class defines a booking-object with all properties needed for a booking
 * @author Loris Gasser
 */
export default class Reservation{

  constructor(startTime, endTime, price, bookingReference, bookingItemId, bookingUserId) {
    this.startTime = startTime
    this.endTime = endTime
    this.price = price
    this.bookingReference = bookingReference
    this.bookingItemId = bookingItemId
    this.bookingUserId = bookingUserId
  }


  /**
   * Gets the startTime of the booking
   * @returns {*} Returns the startTime
   */
  getStartTime(){
    return this.startTime
  }

  /**
   * Gets the endTime of the booking
   * @returns {*} Returns the endTime
   */
  getEndTime(){
    return this.endTime
  }

  /**
   * Gets the price of the booking
   * @returns {*} Returns the price
   */
  getPrice(){
    return this.price
  }

  /**
   * Gets the bookingReference of the booking
   * @returns {*} Returns the bookingReference
   */
  getReferenceBooking(){
    return this.bookingReference
  }

  /**
   * Gets the bookingItemId of the booking-object
   * @returns {*} Returns the ID of the bookingItem
   */
  getBookingItemId(){
    return this.bookingItemId
  }

  /**
   * Gets the bookingUserId of the booking
   * @returns {*} Returns the ID of the bookingUser
   */
  getBookingUserId(){
    return this.bookingUserId
  }
}
