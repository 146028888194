import {html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/VerticalNavigation/VerticalNavigation.js'
import WebComponent from '../../../WebComponent.js'
import Navigation from '../../Navigation/Navigation.js'
import AuthorizationFilter from '../../Authorization/AuthorizationFilter.js'
import Authorization from '../../Authorization/Authorization.js'

export default class AdministrationNavigation extends WebComponent {

  constructor() {
    super();
  }

  get translationFile() {
    return './views/Administration/Navigation/i18n.json'
  }

  connectedCallback() {
    super.connectedCallback()
    this.navigationListener = _ => this.reload();
    document.addEventListener(Navigation.NAVIGATION_HAPPENED, this.navigationListener)
    this.render()
  }

  disconnectedCallback() {
    document.removeEventListener(Navigation.NAVIGATION_HAPPENED, this.navigationListener)
  }

  getTemplate() {

    const allItems = {
      Administration_BookingItems: {
        text: this.i18n.translate('AdminBookingItems'),
        url: 'Administration_BookingItems'
      },
      Administration_User: {
        text: this.i18n.translate('User'),
        url: 'Administration_User'
      }
    }
    const items = getNavigationHash() === Authorization.VIEWS().notAuthorized ? null : AuthorizationFilter.viewsAllowed(Object.keys(allItems)).map(key => allItems[key])
    if (items === null) {
      return html``
    }
    items[0].active = true;
    return html`
            <isceco-vertical-navigation
                    .items="${items}">
            </isceco-vertical-navigation>
        `
  }
}
customElements.define('reservierung-frontend-administration-navigation', AdministrationNavigation)
