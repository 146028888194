/**
 * This class formats dates and times in different ways
 * As well as it compares datetime and calculates new dates
 * @author Loris Gasser
 */
export default class DateTimeFormatter {

  constructor() {
    this.workDays = 5
    this.weekDates = []
    this.workHours = []
  }

  /**
   * Formats date, so it can be filled in a dateinput
   * @param date The date that is to format
   * @returns {string} Returns the new formatted Date as String
   */
  formatInputDate(date){
    const month = date.getMonth()+1
    const day = date.getDate() <= 9 ? '0' + date.getDate() : date.getDate()
    const formattedMonth = month <= 9 ? '0' + month : month
    return date.getFullYear()+'-'+formattedMonth+'-'+day
  }

  /**
   * Formats time, so it can be used in a cell
   * @param time The Time that is to format
   * @returns {string} Returns the newly formatted time as String
   */
  formatCellTime(time) {
    const hour = time.getHours() <= 9 ? '0' + time.getHours() : time.getHours()
    return hour + ':00'
  }

  /**
   * Date gets formatted so that it can be used in a cell
   * @param date The Date to be formatted
   * @returns {string} Returns the formatted date as String
   */
  formatCellDate(date) {
    const month = date.getMonth() + 1
    const day = date.getDate() <= 9 ? '0' + date.getDate() : date.getDate()
    const formattedMonth = month <= 9 ? '0' + month : month

    return day + '.' + formattedMonth
  }

  /**
   * Sets a new datetime
   * @param date The Date the datetime should have
   * @param time The Time the datetime should have
   * @returns {Date} Returns the new date-object
   */
  setDateTime(date, time){
    const splitTime = time.split(':');
    const dateTime = new Date(date)
    dateTime.setHours(splitTime[0], splitTime[1], 0)
    return dateTime
  }

  /**
   * Calculates the date of the start of the week
   * @param date The Date the method calculates the start with
   * @returns {Date} Returns a Date-Object with the start of the week
   */
  getStartOfTheWeek(date){
    const difference = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1)
    const startDate = new Date()
    startDate.setDate(difference)
    return startDate
  }

  /**
   * Sets the dates for whole week
   * @param weekStart The Start of the week that is needed
   */
  setWeekDates(weekStart) {
    const dates = new Date(weekStart)
    for (let i = 0; i < this.workDays; i++) {
      this.weekDates.push(new Date(dates))
      dates.setDate(dates.getDate() + 1)
    }
  }

  /**
   * Gets the date of a cell
   * @param cell The Cell the date is in
   * @returns {Date} Returns a new date-object
   */
  getCellDate(cell) {
    const cellDate = new Date()
    cellDate.setDate(cell.innerText)
    return cellDate
  }

  /**
   * Compares two dates
   * @param date1 The first date-object to compare
   * @param date2 The second date-object to compare
   * @returns {boolean} Returns true if dates are the same and false if it is not
   */
  compareDates(date1, date2) {
    return date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth()
      && date1.getFullYear() === date2.getFullYear()
  }

  /**
   * Compares two dates
   * @param date1 The first date-object with time to compare
   * @param date2 The second date-object with time to compare
   * @returns {boolean} Returns true if dates are the same and false if it is not
   */
  compareDateTime(date1, date2) {
    return date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth()
      && date1.getFullYear() === date2.getFullYear() && date1.getHours() === date2.getHours()
  }

  /**
   * Checks if a time is between two times
   * @param hour The hour that is checked to be between the others
   * @param startHour The StartHour of the time interval
   * @param endHour The EndHour of the time interval
   * @returns {boolean} Returns true if the time is between the interval
   */
  betweenHours(hour, startHour, endHour) {
    return hour.getHours() >= startHour.getHours() && hour.getHours() < endHour.getHours()
  }

  /**
   * Compare two Times
   * @param time1 First time to compare
   * @param time2 Second time to compare
   * @returns {boolean} Returns true if times are same
   */
  compareTime(time1, time2){
    return time1.getHours() === time2.getHours()
  }

  /**
   * Sets the workHours needed and pushes it in the array
   */
  setWorkHours() {
    const hours = new Date()
    for (let hour = 6; hour <= 18; hour++) {
      hours.setHours(hour, 0, 0, 0)
      this.workHours.push(new Date(hours))
    }
  }

  /**
   * Sets the end of the week
   * @param date The date with that the end of the week gets calculated
   * @returns {Date} The Date-Object of the end of the week
   */
  setWeekEnd(date) {
    const weekEnd = new Date()
    weekEnd.setDate(date.getDate() + 4)
    return weekEnd
  }

}
