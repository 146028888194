import WebComponent from '../../../WebComponent.js';
import {html} from '@isceco/widget-library2/external/lit';
import BookingDialog from './BookingDialog.js';
import BookingsService from '../../../services/BookingsService.js';
import DateTimeFormatter from '../../../formatter/DateTimeFormatter.js';
import '@isceco/widget-library2/basic-elements/ActionMenu/ActionMenu.js';
import '@isceco/widget-library2/basic-elements/Button/Button.js';
import Validator from '../../../validator/Validator.js';


/**
 * This class contains all for a calendar
 * In this calendar the bookings of the item get shown
 * @author Loris Gasser
 */
export default class Calendar extends WebComponent {

  constructor(weekStart) {
    super();
    this.dateTimeFormatter = new DateTimeFormatter()
    this.weekStart = weekStart
    this.currentDate = new Date()
    this.userId = 1
    this.validator = new Validator()
    this.clickedCell = null
    this.bookingsByItem = []
    this.selectedCellIndex = []
    this.selectedCellsByBookingId = []

  }

  connectedCallback() {
    super.connectedCallback();
    this.dateTimeFormatter.setWeekDates(this.weekStart)
    this.dateTimeFormatter.setWorkHours()
    const itemId = this.getIdFromUrl()
    this.bookingDialog = new BookingDialog(this.userId)
    new BookingsService().list('item/' + itemId).then(result => {
      this.bookingsByItem = result
      this.renderTemplate()
      this.bookingsByItem.map(booking => this.initTable(booking))
      this.highlightCurrentDay()
      document.getElementById('cancel').disabled = true
      document.getElementById('editBooking').disabled = true
      this.reload()
    })
  }

  getTemplate() {
    return html`
      ${this.getDialog()}

      <div>
        <table id="itemCalendar">
          <thead>
          <tr>
            <th></th>
            ${this.dateTimeFormatter.weekDates.map(date => {

              return html`
                <th>${this.dateTimeFormatter.formatCellDate(date)}</th>`
            })}
          </tr>
          </thead>
          <tbody>
          ${this.dateTimeFormatter.workHours.map(hour => {
            return html`
              <tr>
                <td>${this.dateTimeFormatter.formatCellTime(hour)}</td>
                <td @click="${e => {
                  this.showBookingDialog(e.target)
                }}" class="cell-hover"></td>
                <td @click="${e => {
                  this.showBookingDialog(e.target)
                }}" class="cell-hover"></td>
                <td @click="${e => {
                  this.showBookingDialog(e.target)
                }}" class="cell-hover"></td>
                <td @click="${e => {
                  this.showBookingDialog(e.target)
                }}" class="cell-hover"></td>
                <td @click="${e => {
                  this.showBookingDialog(e.target)
                }}" class="cell-hover"></td>
              </tr>`
          })}
          </tbody>
        </table>
      </div>
      <div>
        <div>
          <isceco-button style="margin-top: 1em" disabled
                         @click="${(_) => this.bookingsByItem.map(booking => this.updateBooking(booking))}"
                         variant="primary" id="editBooking" icon="edit outline">
          </isceco-button>

          <isceco-button style="margin-top: 1em" disabled id="cancel"
                         @click="${(_) => document.querySelector("#cancelBooking").removeAttribute("hidden")}"
                         variant="danger" icon="trash alternate outline">
          </isceco-button>
        </div>

        <isceco-dialog id="cancelBooking"
                       hidden
                       header="Buchung stornieren"
                       description="Möchten Sie wirklich ihre Buchung stornieren?"
                       confirm-button="Bestätigen"
                       Cancel-button="Abbrechen"
                       @submit="${(e) => this.bookingsByItem.map(booking => this.cancelBooking(booking, e))}"
        ></isceco-dialog>

      </div>

    `
  }

  /**
   * Highlights the current day of the week
   * Adds new CSS-Class to cell
   */
  highlightCurrentDay() {
    const table = document.getElementById('itemCalendar')
    const cellDates = table.rows[0].cells
    for (let i = 1; i < cellDates.length; i++) {
      const cellDate = this.dateTimeFormatter.getCellDate(table.rows[0].cells[i])

      if (cellDate.getDate() === this.currentDate.getDate() && cellDate.getMonth() === this.currentDate.getMonth()) {
        for (let j = 1; j < table.rows.length; j++) {
          let cell = table.rows[j].cells[i]
          cell.classList.add('current-date-col')
        }
      }
    }
  }

  /**
   * Prepares the calendar for the user
   * Loads the booking already made in the calendar
   * Adds a CSS-Class to show the user the booked cells
   * @param booking The Booking to load in the table
   */
  initTable(booking) {
    const table = document.getElementById('itemCalendar')
    const bookingStartDateTime = new Date(booking.startTime)
    const bookingEndDateTime = new Date(booking.endTime)
    const cellDates = table.rows[0].cells
    for (let i = 0; i < cellDates.length; i++) {
      const cellDate = this.dateTimeFormatter.getCellDate(cellDates[i])
      if (this.dateTimeFormatter.compareDates(cellDate, bookingStartDateTime)) {
        for (let rowIndex = 0; rowIndex < table.rows.length - 1; rowIndex++) {
          let cellTime = new Date()
          const cellTimeSplit = table.rows[rowIndex + 1].cells[0].innerHTML.split('>')[1].split(':')
          cellTime.setHours(cellTimeSplit[0], cellTimeSplit[1])
          if (this.dateTimeFormatter.betweenHours(cellTime, bookingStartDateTime, bookingEndDateTime)) {
            this.setBackgroundColor(table.rows[rowIndex + 1].cells[i], booking)
          }
        }
      }
    }
  }

  /**
   * Highlights the own booking that was clicked
   * Adds a CSS-Class to highlight the cells
   * @param clickedCell The clicked Cell of the booking
   */
  highlightClickedCells(clickedCell) {
    const table = document.getElementById('itemCalendar')

    for (let i = 1; i < table.rows.length - 1; i++) {
      let cell = table.rows[i].cells[clickedCell.cellIndex]
      if (cell.classList.contains('own-booking')) {
        cell.classList.add('selected-booking')
      }
    }
  }


  /**
   * Cleans the highlighted cells to lowlights them
   * @param clickedCell The cell that was clicked before to highlight all the cells of the own booking
   */
  cleanHighlightedCells(clickedCell) {
    const table = document.getElementById('itemCalendar')
    if (clickedCell) {
      for (let i = 1; i < table.rows.length; i++) {
        table.rows[i].cells[clickedCell.cellIndex].classList.remove('selected-booking')
      }
    }
  }

  /**
   * Saves the bookingId with the affected row- and cellindex in an array
   * @param clickedCell The Cell that was clicked to get the cellIndex
   * @param booking The Booking to be checked if it is the one to the clicked Cell
   */
  getBookingIdByTime(clickedCell, booking) {
    const table = document.getElementById('itemCalendar')
    const cellDate = this.dateTimeFormatter.getCellDate(table.rows[0].cells[clickedCell.cellIndex])
    const bookingStartTime = new Date(booking.startTime)
    const bookingEndTime = new Date(booking.endTime)
    this.getRowIndexes(clickedCell)
    let startTime = this.getClickedCellsStartTime(table, cellDate)
    let endTime = this.getClickedCellsEndTime(table, cellDate)
    if (clickedCell.classList.contains('own-booking')) {
      if (this.dateTimeFormatter.compareDates(bookingStartTime, startTime) && this.dateTimeFormatter.compareDates(endTime, bookingEndTime)) {
        for (let j = 0; j < table.rows.length - 1; j++) {
          if (this.dateTimeFormatter.compareTime(startTime, bookingStartTime) && this.dateTimeFormatter.compareTime(endTime, bookingEndTime)) {
            this.selectedCellsByBookingId.push({
              bookingId: booking.id,
              columnIndex: clickedCell.parentNode.rowIndex,
              rowIndex: j
            })
          }
        }
      }
    }
  }

  /**
   * Method to handle the behaviour if the User clicks on a cell
   * Distinction between booked cells, own bookings and free cells
   * @param clickedCell The Cell that was clicked
   */
  showBookingDialog(clickedCell) {
    this.cleanHighlightedCells(this.clickedCell)
    this.clickedCell = clickedCell

    if (clickedCell.classList.contains('booked-cell')) {
      document.getElementById('cancel').disabled = true
      document.getElementById('editBooking').disabled = true
      window.showAlert('Das Objekt kann nicht gebucht werden',
        'Das Objekt wurde zuvor bereits zu diesem Zeitpunkt gebucht. ' +
        'Bitte wählen Sie einen anderen Zeitpunkt zur Buchung', 'warning')

    } else if (clickedCell.classList.contains('own-booking')) {
      this.selectedCellIndex = []
      this.bookingsByItem.map(booking => this.getBookingIdByTime(clickedCell, booking))
      console.log(this.selectedCellsByBookingId)
      this.highlightClickedCells(clickedCell)
      document.getElementById('cancel').disabled = false
      document.getElementById('editBooking').disabled = false
    } else {
      document.getElementById('cancel').disabled = true
      document.getElementById('editBooking').disabled = true
      this.bookingDialog.changeValues(0, null, this.dateTimeFormatter.weekDates[clickedCell.cellIndex - 1])
      this.bookingDialog.querySelector("#form-modal").removeAttribute("hidden")
    }
  }

  /**
   * Prepares the bookingDialog for an update
   * @param booking The booking to be updated
   */
  updateBooking(booking) {
    if (booking.id === this.selectedCellsByBookingId.at(0).bookingId) {
      if (!this.validator.isDatePresent(new Date(booking.startTime))) {
        if (this.selectedCellsByBookingId.at(0).rowIndex >= 6) {
          this.bookingDialog.changeValues(2, booking)

        } else if (this.selectedCellsByBookingId.at(0).rowIndex < 6 &&
          this.selectedCellsByBookingId.at(this.selectedCellsByBookingId.length - 1).rowIndex <= 6) {
          this.bookingDialog.changeValues(1, booking)
        } else {
          this.bookingDialog.changeValues(0, booking)
        }
        this.bookingDialog.querySelector("#form-modal").removeAttribute("hidden")
      } else {
        window.showAlert('Der Parkplatz kann nicht bearbeitet werden',
          'Die Reservation liegt in der Vergangenheit, weshalb Sie es nicht mehr bearbeiten können',
          'warning')
      }
    }
  }

  /**
   * Cancels a new booking
   * @param booking The Booking that has to be canceled
   * @param event The Event that is passed by clicking the button
   */
  cancelBooking(booking, event) {
    console.log(this.selectedCellsByBookingId)
    if (event.detail.confirmed) {
      if (booking.id === this.selectedCellsByBookingId.at(0).bookingId) {
        if (!this.validator.isDatePresent(new Date(booking.startTime))) {

          new BookingsService().cancel('cancel/' + booking.id).then(_ => {
            location.reload()
          })
        } else {
          window.showAlert('Der Parkplatz kann nicht bearbeitet werden',
            'Die Reservation liegt in der Vergangenheit, weshalb Sie es nicht mehr bearbeiten können',
            'warning')
        }
      }
    }
    event.target.setAttribute("hidden", "")

  }

  /**
   * Checks if the booking is one f the own bookings
   * @param booking The Booking to check
   * @returns {boolean} Returns true if it is the own booking and false if not
   */
  isOwnBooking(booking) {
    return booking.bookingUser.id === this.userId
  }

  /**
   * Returns a new Dialog to make a new Booking
   * @returns {BookingDialog} Returns a new bookingdialog
   */
  getDialog() {
    return this.bookingDialog
  }

  /**
   * Gets the startTime of the booking with the table
   * @param table The Table  that is needed for the calendar
   * @param cellDate The Date of affected column
   * @returns {Date} Returns a new datetime as date-object
   */
  getClickedCellsStartTime(table, cellDate) {
    return this.dateTimeFormatter.setDateTime(cellDate,
      table.rows[this.selectedCellIndex.at(0)].cells[0].innerText)
  }

  /**
   * Gets the endTime of the booking with the table
   * @param table The Table needed for the calendar
   * @param cellDate The Date of the affected column
   * @returns {Date} Returns a new datetime as date-object
   */
  getClickedCellsEndTime(table, cellDate) {
    return this.dateTimeFormatter.setDateTime(cellDate,
      table.rows[Number(this.selectedCellIndex.at(this.selectedCellIndex.length - 1) + 1)].cells[0].innerText)
  }

  /**
   * Saves all rowindexes in an array based on the cell clicked
   * @param clickedCell The Cell that was clicked
   */
  getRowIndexes(clickedCell) {
    const table = document.getElementById('itemCalendar')
    for (let j = 0; j < table.rows.length; j++) {
      const cell = table.rows[j].cells[clickedCell.cellIndex]
      if (cell.classList.contains('own-booking')) {
        this.selectedCellIndex.push(j)
      }
    }
  }

  /**
   * Sets the backgroundcolor of the cell depending on if it is the own booking
   * @param cell The Cell to set the color
   * @param booking The Booking to check if it is an own booking
   */
  setBackgroundColor(cell, booking) {
    if (this.isOwnBooking(booking)) {
      cell.classList.add('own-booking')
    } else {
      cell.classList.add('booked-cell')
    }
    cell.classList.remove('cell-hover')
  }

}
customElements.define('reservierung-frontend-calendar', Calendar)
