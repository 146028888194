import WebComponent from '../../WebComponent.js';
import {html} from '@isceco/widget-library2/external/lit';
import DetailBooking from '../../views/Booking/DetailBooking/DetailBooking.js'
import BookingItems from '../../views/Booking/BookingItems/BookingItems.js'
import '../../views/Booking/BookingNavigation/BookingNavigation.js'
import AuthorizationFilter from '../Authorization/AuthorizationFilter.js';

/**
 * This class is the central view for the booking components
 * @author Loris Gasser
 */
export default class Booking extends WebComponent{

  connectedCallback() {
    super.connectedCallback();
    this.reload()
  }

  render() {
    super.render();
    if (getNavigationHash() === 'Booking'){
      navigate({to:'BookingItems'})
    }
  }

  getTemplate(){
    return html`
      <div style="display: flex; height: auto">
        <div style="flex-grow: 0; margin-top: 10px;">
          <reservierung-frontend-booking-navigation></reservierung-frontend-booking-navigation>
        </div>
        <div style="flex-grow: 1; margin-left: 20px; margin-top: 20px;">
          ${this.getContent()}
        </div>
      </div>
    `
  }

  /**
   * Loads the Content of the booking components based on the navigation Hash
   * @returns {BookingItems|DetailBooking|{_$litType$: *, strings: *, values: *[]}} Returns the new instantiated booking component
   */
  getContent(){
    if (!AuthorizationFilter.viewAllowed(getNavigationHash())) {
      navigate({to: 'notAuthorized'})
      return html``
    }
    if (getNavigationHash() === 'BookingItems'){
      return new BookingItems()
    } else if (getNavigationHash() === 'DetailBooking'){
      return new DetailBooking()

    }
  }
}
customElements.define('reservierung-frontend-booking', Booking)
