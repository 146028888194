import WebComponent from '../../../WebComponent.js';
import Navigation from '../../Navigation/Navigation.js';
import AuthorizationFilter from '../../Authorization/AuthorizationFilter.js';
import Authorization from '../../Authorization/Authorization.js';
import {html} from '@isceco/widget-library2/external/lit';

/**
 * In this class the vertical Navigation is defined
 * This only for the booking part of the application
 * @author Loris Gasser
 */
export default class BookingNavigation extends WebComponent{

  /**
   * Gets the JSON file for translations
   * @returns {string} The Path of the file
   */
  get translationFile() {
    return './views/Booking/BookingNavigation/i18n.json'
  }
  connectedCallback() {
    super.connectedCallback();
    this.navigationListener = _ => this.reload()
    document.addEventListener(Navigation.NAVIGATION_HAPPENED, this.navigationListener)
    this.render()
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    document.removeEventListener(Navigation.NAVIGATION_HAPPENED, this.navigationListener)
  }

  getTemplate(){
    const bookingItems = [
      {
        text: this.i18n.translate('bookingNavigation.bookingItems'),
        url: 'BookingItems'
      }
    ]
    const items = getNavigationHash() === Authorization.VIEWS().notAuthorized ? null : AuthorizationFilter.viewsAllowed(Object.keys(bookingItems)).map(key => bookingItems[key])

    if (items === null){
      return html``
    }
    items[0].active = true

    return html`<isceco-vertical-navigation  .items="${items}" ></isceco-vertical-navigation>`

  }

}
customElements.define('reservierung-frontend-booking-navigation', BookingNavigation)
