import {html} from '@isceco/widget-library2/external/lit'
import WebComponent from '../../../WebComponent.js'

export default class AdminBookingItems extends WebComponent {

  get translationFile() {
    return './views/Administration/AdminBookingItems/i18n.json'
  }

  constructor() {
    super()
    //this.bookingItemsService = new  BookingItemsService()
  }

  static get EVENT_KEYS() {
    return {
      RELOAD_TRIGGERED: 'reload-triggered'
    }
  }

  async connectedCallback() {
    super.connectedCallback()
    this.bookingListService = await this.bookingItemsService.list()
    this.columns = [
     /* BookingItemsColumns.AVAILABLE_COLUMN(),
      BookingItemsColumns.BASIC_PRICE_COLUMN(),
      BookingItemsColumns.CREATED_AT_COLUMN(),
      BookingItemsColumns.DESCRIPTION_COLUMN(),
      BookingItemsColumns.ID_COLUMN(),
      BookingItemsColumns.INVENTORY_CODE_COLUMN(),
      BookingItemsColumns.LAST_UPDATED_AT_COLUMN(),
      BookingItemsColumns.NAME_COLUMN(this.i18n),
      BookingItemsColumns.TYPE_COLUMN(this.i18n),
      BookingItemsColumns.CREATED_AT_COLUMN(),
      BookingItemsColumns.LAST_UPDATED_AT_COLUMN()
      //BookingItemsColumns.AKTIONEN_COLUMN({callback: this._rowActionMenu})*/
    ]
    this.render()
  }

  getTemplate() {
    return html`
      <isceco-title text="${this.i18n.translate('bookingitems.header')}">
        <isceco-button
          title="${this.i18n.translate('bookingitems.create.button')}"
          icon="plus"
          variant="primary"
          size="small"
          @click="${_ => navigate({to: 'Administration_BookingItemsEdit'})}"
        ></isceco-button>
      </isceco-title>

      <isceco-filter-group>
        <isceco-date-filter label="Registered" key="registered"></isceco-date-filter>
        <isceco-string-filter label="Name" key="name"></isceco-string-filter>
      </isceco-filter-group>

      ${this._getTemplateList()}
    `
  }

  _getTemplateList() {
    return html`
      <isceco-list
        .listService="${this.bookingListService}"
        .columns="${this.columns}"
        .i18n="${this.i18n}"
        update-event="${AdminBookingItems.EVENT_KEYS.RELOAD_TRIGGERED}"
      ></isceco-list>
    `
  }

  _rowActionMenu = (row, action) => {
    switch (action) {
      case 'edit':
        navigate({to: 'Administration_BookingItemsEdit', id: row.id})
        break
      case 'copy':
        navigate({to: 'Administration_BookingItemsEdit', id: row.id, params: {copy: true}})
        break
      default:
        break
    }
  }
}
customElements.define('reservierung-frontend-admin-booking-items', AdminBookingItems)
