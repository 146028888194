import {css, html} from '@isceco/widget-library2/external/lit';
import WebComponent from '../../WebComponent.js';

export default class NoContent extends WebComponent {

  get css() {
    return css`
      div {
        text-align: center;
      }
    `
  }

  connectedCallback() {
    super.connectedCallback()
    this.render()
  }

  getTemplate() {
    return html`
      <div>
        <img
          src="https://kidmograph.tumblr.com/post/47406119796/404-my-brain"
          alt="404 nothing found">
      </div>
    `
  }
}
customElements.define('reservierung-frontend-no-content', NoContent)
