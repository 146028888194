import {html} from '@isceco/widget-library2/external/lit';
import WebComponent from '../../WebComponent.js';
import Navigation from '../Navigation/Navigation.js';
import NoContent from '../NoContent/NoContent.js';
import LogSettings from '../LogSettings/LogSettings.js';
import NotAuthorized from '../NotAuthorized/NotAuthorized.js';
import AuthorizationFilter from '../Authorization/AuthorizationFilter.js';
import Authorization from '../Authorization/Authorization.js';
import Administration from '../Administration/Administration.js'
import Booking from '../Booking/Booking.js';

export default class MainView extends WebComponent {

  constructor() {
    super()
  }

  get css() {
    return css`
      :host {
        overflow: hidden;
      }

      div {
        text-align: left;
      }
    `
  }

  connectedCallback() {
    super.connectedCallback()
    this.oldChild = null
    this.navigationListener = e => {
      this.loadView(e.detail)
    }
    document.addEventListener(Navigation.NAVIGATION_HAPPENED, this.navigationListener)
    this.loadView(getNavigationHash())

  }

  disconnectedCallback() {
    super.disconnectedCallback()
    this.removeEventListener(Navigation.NAVIGATION_HAPPENED, this.navigationListener)
  }

  loadView(linkName) {
    if (isEmpty(linkName)) {
      this.redirectToDefault()
    }
    const newChild = this.returnNewChild(linkName)
    if (this.oldChild) {
      this.root.replaceChild(newChild, this.oldChild)
    } else {
      this.root.appendChild(newChild)
    }

    this.oldChild = newChild
  }

  returnNewChild(linkName) { //NOSONAR
    if (!AuthorizationFilter.viewAllowed(linkName)) {
      return new NotAuthorized();
    }

    if (isEmpty(linkName)) {
      if (hasRole(Authorization.ROLES.user)) {
        return new LogSettings()
      } else {
        return new Booking()
      }
    }

    switch (linkName) {
      case 'LogSettings':
        return new LogSettings()

      case 'Booking':
      case 'BookingItems':
      case 'DetailBooking':
        return new Booking()

      case 'Administration':
      case 'Administration_User':
        return new Administration()

      default:
        return new NoContent()
    }
  }

  redirectToDefault() {
    if (window.keycloak && window.hasRole(Authorization.ROLES.betrieb)) {
      navigate({to: 'LogSettings'})
    }
    navigate({to: 'BookingItems'})
  }

  getTemplate() {
    return html`Reservierung-frontend-main-view`
  }
}

customElements.define('reservierung-frontend-main-view', MainView)
