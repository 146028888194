import {css, html} from '@isceco/widget-library2/external/lit';
import WebComponent from '../../WebComponent.js';

export default class NotAuthorized extends WebComponent {

  get css() {
    return css`
      div {
        text-align: center;
      }
    `
  }

  connectedCallback() {
    super.connectedCallback()
    this.render()
  }

  getTemplate() {
    return html`
      <div>
        <h1>You are not authorized! Please contact an ISCeco Administrator!</h1></br>
        <img
          src="https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExcjB3NTJqcDQ3c3E5YzhiNWRtY3YxMjUxdnJ1aTVwNDZoNm5mNXZ1cCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/2qwEtbD7j7AHcMNCXe/giphy.gif"
          alt="not authorized">
      </div>
    `
  }
}
customElements.define('reservierung-frontend-not-authorized', NotAuthorized)
