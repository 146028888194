import BaseService from '../BaseService.js';

/**
 * All Requests that are made for bookings use this class
 * The base URL is given but can get changed
 * @author Loris Gasser
 */
export default class BookingsService extends BaseService{

  constructor(relativeBase = 'bookings') {
    super(relativeBase);
  }

  /**
   * Sends a Request to cancel a certain booking
   * @param serviceName The last part of the URL needed to find the Resource
   * @returns {Promise<*>} A Promise with the Response for the Request
   */
  async cancel(serviceName) {
    await this.retrieveBackendUrl();
    const url = (serviceName) ? `${this.backendUrl}/${serviceName}` : this.backendUrl
    return this.checkError(
      fetch(`${url}`, {
        method: 'PUT',
        cache: 'reload',
        headers: this.getJsonHeader(),
      }).then(this._resetChangesAndReturn)
    )
  }

}
